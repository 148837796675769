<template>

  <v-dialog  v-model="drawer" class="ma-0"   @click:outside="close" :style="{height:$vuetify.breakpoint.height+'px !important'}" :width="$vuetify.breakpoint.width+'px'">
    <transition name="bounce">
      <v-card tile v-if="drawer" class="ma-0 pa-0" :loading="loadingPDF" style="position:absolute !important;right: 0;top:0;" :width="$vuetify.breakpoint.xsOnly ? '100%' : edit ? '80%' : '30%'"  :height="$vuetify.breakpoint.height+'px'">
        <v-container class="ma-0 pa-0" >
          <v-row no-gutters >
            <v-col :cols="edit ? 4 : 12" :style="{height:$vuetify.breakpoint.height+'px !important',backgroundColor:'lightgrey'}">
              <v-card tile elevation="0">
                <v-toolbar class="py-0" elevation="1" dark color="primary" height="48" tile>
                  <v-icon @click="close">
                    mdi-close
                  </v-icon>
                  <v-spacer/>
                  <v-toolbar-title class="text-center">
                    {{$t('settings.lang_pdfOptions')}}
                  </v-toolbar-title>
                  <v-spacer/>
                </v-toolbar>
                <v-card-text class="mt-12">
                  <v-row class="mt-12">
                    <v-col cols="12" v-if="!pay" >
                      <v-btn color="success" elevation="1" block class="ma-0" small outlined dark depressed @click="book()" :loading="loading" :disabled="loading">
                        {{$t('accounting.lang_bookAndCommit')}}
                      </v-btn>
                    </v-col>
                    <v-col cols="12" v-else>
                      <v-btn color="success" elevation="1" block class="ma-0" small outlined dark depressed @click="commitPay()" :loading="loading" :disabled="loading">
                        {{$t('accounting.lang_bookAndPay')}}
                      </v-btn>
                    </v-col>
                    <v-col cols="12" v-if="edit">
                      <v-btn color="primary" elevation="1" block class="ma-0" small outlined dark depressed @click="updateDraft()" :loading="loading" :disabled="loading">
                        {{$t('generic.lang_edit')}}
                      </v-btn>
                    </v-col>
                    <v-col cols="12" v-else>
                      <v-btn color="warning" elevation="1" block class="ma-0" small outlined dark depressed @click="saveAsDraft()" :loading="loading" :disabled="loading">
                        {{$t('erp.lang_saveAsDraft')}}
                      </v-btn>
                    </v-col>
                    <v-col cols="12" v-if="edit">
                      <v-card color="primary" elevation="1" block class="ma-0 pa-1 text-center text-uppercase" small outlined dark @click="saveAsProFormat" :loading="loading" :disabled="loading">
                        {{$t('accounting.lang_saveAsProFormaInvoice')}}
                      </v-card>
                    </v-col>

                    <v-col cols="12" v-if="iframePDFContent">
                      <v-btn color="success"  elevation="1" block class="ma-0" small @click="downloadPDF" outlined dark  depressed :loading="loading" :disabled="loading">
                        <v-icon>mdi-download</v-icon>{{$t('generic.lang_download')}}
                      </v-btn>
                    </v-col>

                    <v-col cols="12" v-if="edit">
                      <v-btn color="error" elevation="1" block class="ma-0" small dark depressed @click="deleteDraft" :loading="loading" :disabled="loading">
                        {{$t('generic.lang_delete')}}
                      </v-btn>
                    </v-col>


                  </v-row>
                </v-card-text>
              </v-card>

            </v-col>
            <v-col cols="8" :style="{height:$vuetify.breakpoint.height+'px !important'}" v-if="edit && iframePDFContent && showPdf">
              <iframe :src="iframePDFContent" v-if="iframePDFContent&&iframePDFContent.length > 0" data-role="pdf" width="100%" style="border: 0;" height="100%"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </transition>
  </v-dialog>

</template>

<script>
import FileSaver from 'file-saver';
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
export default {
  name: "BillingPDFViewerComponent",
  props:{
    drawer:Boolean,
    edit:{
      type:Boolean,
      default:false,
    },
    loading:{
      type:Boolean,
      default:false,
    },
    pay:{
      type:Boolean,
      default:false,
    },
    showPdf:{
      type:Boolean,
      default:true,
    }
  },
  data(){
    return{
      ENDPOINTS,
      loadingPDF:false,
      iframePDFContent:null,
      responseData:null,
    }
  },
  watch:{
    drawer(val){
      if(val&&this.showPdf){
        this.iframePDFContent=null
        this.getPDF();
      }
    }
  },
  computed:{

  },
  methods:{
    close(){
      this.$emit('close')
    },
    saveAsDraft(){
      this.$emit('saveAsDraft');
    },
    updateDraft(){
      this.$emit('update');
    },
    book(){
      this.$emit('book');
    },
    commitPay(){
      this.$emit('pay');
    },
    saveAsProFormat(){
      if(this.loading)
        return;

      this.$emit('toProformat');
    },
    deleteDraft(){
      this.$emit('delete');
    },
    getPDF() {
      this.loadingPDF=true

      this.axios.post('get/facture/invoiceDraftData4PDF/', {
            invoiceDraftUuid:this.$route.params.uuid
          },
          {
            responseType: 'arraybuffer',
          }).then((res) => {


        //GENERATE PDF CONTENT FOR iFRAME
        this.responseData=res.data
        const blobContent = new Blob([res.data], {type: "application/pdf"});
        this.iframePDFContent = URL.createObjectURL(blobContent);

      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loadingPDF=false
      })
    },
    downloadPDF(){
      if(this.iframePDFContent && this.iframePDFContent.length>0&& this.responseData){
        FileSaver.saveAs(new Blob([this.responseData], {
          type: "application/pdf"
        }), "labels.pdf");
      }
    },
  },
  mounted() {

  }
}
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in .6s;
  animation-timing-function: ease-in-out;
}
.bounce-leave-active {
  animation: bounce-in .6s reverse;
  animation-timing-function: ease-in-out;
}
@keyframes bounce-in {
  0% {
    transform: translateX(1500px);

  }
  100% {
    transform: translateX(0);
  }
}
</style>
